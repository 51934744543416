body {
  font-family: "Tajawal", sans-serif;
  width: "100%";
  padding: 0;
  margin: 0;
}
.primaryFont {
  font-family: "Cairo", sans-serif;
}

.secondaryFont {
  font-family: "Tajawal", sans-serif;
}
