@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logo {
  overflow: hidden;
  max-width: 100vw;
  padding: 10px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}
.logo:before,
.logo:after {
  position: absolute;
  top: 0px;
  width: 50px;
  height: 150%;
  content: "";
  z-index: 2;
}
.logo:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}
.logo:after {
  right: 0px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.logo:hover .logo-slide {
  overflow: hidden;
  animation-play-state: paused;
}
.logo-slide {
  display: inline-block;
  animation: 70s slide infinite linear;
}
.logo-slide img {
  height: 50px;
  margin: 0 30px;
}
